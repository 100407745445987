import { NavService, AuthService } from "@sk/services";
import { Toaster } from "@sk/uis";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Auth.module.scss";

import logo from "../images/logo-header.svg";

const ChangePassword = () => {
  const defaultFormData = { newPwd: "", confirmPwd: "" };

  const router = useNavigate();

  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);

  const onSubmit = (ev) => {
    ev.preventDefault();
    const v = validate();
    if (v.status === false) {
      Toaster.error(v.msg);
      return;
    }
    doChangePwd();
  };

  const doChangePwd = async () => {
    const f = formData;

    setLoading(true);

    const e = AuthService.getLoggedInEmp();

    const r = await AuthService.resetPwd(e?.username, {
      newPassword: f.newPwd,
      confirmNewPassword: f.confirmPwd,
      userType: "Employee",
      appType: "ERP",
    });

    setLoading(false);

    if (r.statusCode === 200) {
      const resp = r.resp;
      NavService.replace(router, "/auth/login");
      Toaster.success(resp.message || "Reset successfully");
    } else {
      Toaster.error(
        r.resp.message || "Failed to Change Password, please try again"
      );
    }
  };

  const validate = () => {
    const f = formData;
    let msg = "";
    if (!f.newPwd) {
      msg = "Please provide New Password";
    } else if (!f.confirmPwd) {
      msg = "Please provide Confirm Password";
    } else if (f.newPwd != f.confirmPwd) {
      msg = "Password is not matching";
    } else {
      msg = "";
    }
    return {
      msg: msg,
      status: msg ? false : true,
    };
  };

  const login = () => {
    NavService.replace(router, "/auth/login");
  };

  return (
    <>
      <div className={styles.authPage}>
        <div className={styles.mn}>
          <div className={styles.curve}>
            <div className={styles.cnt}>
              <div className={styles.logo}>
                <img src={logo} alt="logo" />
              </div>
              <div className={styles.caption}>
                Brings the power of e-commerce to small towns in India
              </div>
            </div>
          </div>
          <div className={styles.inr}>
            <div className="row g-0 justify-content-center">
              <div className="col-md-8 col-lg-5 col-xl-4">
                <div className="card border-o mb-4 ms-3 me-3">
                  <div className="card-body">
                    <div className="p-3">
                      <div className="text-center mb-4">
                        <div className="fs-5 fw-semibold text-primary mb-2">
                          Change Password
                        </div>
                        <div className="fs-7 text-muted">
                          Create new password here.
                        </div>
                      </div>

                      <form onSubmit={onSubmit} autoComplete="off">
                        <div className="mb-4">
                          <label className="form-label">
                            New Password <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              name="userName"
                              type={showNewPwd ? "text" : "password"}
                              value={formData.newPwd}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  newPwd: e.target.value,
                                })
                              }
                              className="form-control"
                              placeholder="Enter New Password"
                            />
                            <a
                              className="input-group-text cursor-pointer"
                              onClick={() => setShowNewPwd((v) => !v)}
                            >
                              <button
                                type="button"
                                className="btn btn-link p-0"
                              >
                                <i
                                  className={
                                    showNewPwd ? "bi-eye-slash" : "bi-eye"
                                  }
                                ></i>
                              </button>
                            </a>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label className="form-label">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              name="userName"
                              type={showConfirmPwd ? "text" : "password"}
                              value={formData.confirmPwd}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  confirmPwd: e.target.value,
                                })
                              }
                              className="form-control"
                              placeholder="Confirm Password"
                            />
                            <span
                              className="input-group-text cursor-pointer"
                              onClick={() => setShowConfirmPwd((v) => !v)}
                            >
                              <i
                                className={
                                  showConfirmPwd ? "bi-eye-slash" : "bi-eye"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>

                        <div className="mb-3">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : null}
                            <span> Submit </span>
                          </button>
                        </div>

                        <div className="text-center fs-7 text-muted">
                          <span>Back to Login, </span>
                          <span
                            className="fw-semibold cursor-pointer text-primary"
                            onClick={login}
                          >
                            click here
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cpy}>&#169; 2022 StoreKing.</div>
      </div>
    </>
  );
};

export default memo(ChangePassword);
