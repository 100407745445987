import {
  AuthService,
  EmployeeService,
  FranchiseService,
  NavService,
  RolesService,
} from "@sk/services";
import { BusyLoader, GoogleSignIn, OtpModal, Toaster } from "@sk/uis";
import { produce } from "immer";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Auth.module.scss";

import logo from "../images/logo-header.svg";

const Login = () => {
  const [authStatus, setAuthStatus] = useState("unauthenticated");

  const [user, setUser] = useState({});

  const navigate = useNavigate();

  const defaultFormData = { user: "", pwd: "", otpResp: {} };

  const userIdRef = useRef();

  const [busyLoader, setBusyLoader] = useState({ message: "", loading: false });

  const [formData, setFormData] = useState(defaultFormData);

  const [loading, setLoading] = useState(false);

  const [showOtp, setShowOtp] = useState(false);

  const [showPwd, setShowPwd] = useState(false);

  useEffect(() => {
    if (AuthService.isUserLoggedIn()) {
      NavService.to(navigate, "/auth/init");
    }
  }, [navigate]);

  const onGSigninCallback = (r) => {
    if (r.status == "authenticated") {
      setAuthStatus("authenticated");
      setUser(r.user);
    } else {
      setAuthStatus("unauthenticated");
    }
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    const v = validate();
    if (v.status === false) {
      Toaster.error(v.msg);
      return;
    }
    // Need to unComment in future
    // if there is no email
    if (!user || !user.email) {
      alert(
        "Seems like you have not signed in using Google, please signin via google"
      );
      location.reload();
      return;
    }

    // if email of other than storeking
    if (!/@storeking\.in$/.test(user.email)) {
      alert("Invalid domain, please use StoreKing official email ID");
      location.reload();
      return;
    }

    doLogin();
  };

  const doLogin = async () => {
    try {
      const f = formData;

      setLoading(true);

      const r = await AuthService.doLogin({
        appType: "ERP",
        email: user.email,
        password: f.pwd,
        userType: "Employee",
        username: f.user,
      });

      setLoading(false);

      // respose code Errors

      if (r.statusCode === 200) {
        const resp = r.resp;

        if (resp.resetPassword) {
          setToken(resp.token);
          NavService.to(navigate, "/auth/change-password");
          return;
        }

        if (resp.otpRequired || resp.enableOtp) {
          setFormData(
            produce((draft) => {
              draft.otpResp = resp;
            })
          );
          setShowOtp(true);
        } else {
          userIdRef.current = resp.userId;
          await authSuccessHandler(resp);
          NavService.to(navigate, "/auth/init");
        }
      } else {
        Toaster.error(r.resp.message || "Failed to login, please try again");
      }
    } catch (error) {
      Toaster.error("Opps!, something went wront, please try again");
    }
  };

  const validate = () => {
    const f = formData;
    let msg = "";
    if (!f.user) {
      msg = "Please provide username";
    } else if (!f.pwd) {
      msg = "Please provide Password";
    } else {
      msg = "";
    }
    return {
      msg: msg,
      status: msg ? false : true,
    };
  };

  const authSuccessHandler = async (r) => {
    setToken(r.token);

    // GET EMP DETAILS AND ASSIGNED WAREHOUSE DETAILS
    const emp = await EmployeeService.getEmployee(r.employee);

    const groupsResp = await RolesService.getEmpAssignedGroups(r.employee);

    // Role are in Local Storage need To Change in Future
    AuthService.setUserRolesInLocal(groupsResp?.resp);

    const whData = emp?.resp?.accessConfig || [];
    const whIds = whData.map((x) => x.whId);
    const defaultWh = whData.find((x) => x.default === true) || {};
    let whs = [];
    if (whIds.length > 0) {
      const fran = await FranchiseService.getFranchises({
        select: "name,state,district",
        filter: { _id: { $in: whIds } },
      });
      whs = (fran?.resp || []).map((x) => {
        return {
          ...x,
          isDefault: defaultWh.whId === x._id,
        };
      });
    }

    // NO EMP FOUND THROW ERR
    if (!emp?.resp?._id) {
      setBusyLoader({ loading: false, message: "" });
      setShowOtp(false);
      Toaster.error("Failed to fetch employee details");
      return;
    }

    const e = emp?.resp;
    AuthService.setEmpInLocal({
      userId: userIdRef.current,
      employee: e._id,
      employeeName: e.firstName,
      roleId: r.roleId,
      username: r.username,
      accessConfig: e.accessConfig,
      warehouses: whs,
      email: user?.email,
      defaultWh: whs.find((x) => x.isDefault),
      _whId: whs.map((x) => x._id),
      sipId: e.sipId,
      agentId: e.agentId,
    });
  };

  const otpVerifyCallback = async (data) => {
    const f = formData;
    setBusyLoader({
      loading: true,
      message: "We are validating the OTP, please wait",
    });
    userIdRef.current = f.otpResp.userId;
    const r = await AuthService.verifyLoginOtp({
      userid: f.otpResp.userId,
      id: f.otpResp._id,
      otp: Number(data.otp),
    });
    if (r.statusCode === 200) {
      await authSuccessHandler(r.resp);
      setShowOtp(false);
      setBusyLoader({ loading: false, message: "" });
      NavService.to(navigate, "/auth/init");
    } else {
      Toaster.error(
        r.resp.message || "Failed to validate the OTP, please try again"
      );
      setBusyLoader({ loading: false, message: "" });
    }
  };

  const setToken = (token) => {
    AuthService.setTokenInLocal(token);
  };

  const otpModalCb = (e) => {
    if (e.action == "close") {
      setShowOtp(false);
    }
    if (e.action == "verify") {
      otpVerifyCallback(e.data);
    }
  };

  // const doGSignOut = async () => {};

  const forgotPassword = () => {
    NavService.to(navigate, "/auth/forgot-password");
  };

  // handles the redirection after login success
  // const handleRedirection = async () => {
  //   // pick page details from configured menu
  //   const m = await BuilderMenuService.getList();
  //   let menu = get(orderBy(m?.resp?.data || [], ["priority"]), "[0]", {});
  //   let rd = {};
  //   // check has any submenu and take the first data redirection
  //   if (menu?.subMenus && menu?.subMenus?.length > 0) {
  //     const smenu = get(orderBy(menu.subMenus || [], ["priority"]), "[0]", {});
  //     rd = smenu.redirect || {};
  //   } else {
  //     rd = menu.redirect || {};
  //   }
  //   NavService.to(navigate, rd.path);
  // };

  return (
    <>
      <div className={styles.authPage}>
        <div className={styles.mn}>
          <div className={styles.curve}>
            <div className={styles.cnt}>
              <div className={styles.logo}>
                <img alt="Logo" width={220} height={80} src={logo} />
              </div>
              <div className={styles.caption}>
                Brings the power of e-commerce to small towns in India
              </div>
            </div>
          </div>
          <div className={styles.inr}>
            <div className="row g-0 justify-content-center">
              <div className="col-md-8 col-lg-5 col-xl-4">
                <div className="card shadow-sm mb-4 ms-3 me-3">
                  <div className="card-body">
                    <div className="p-3">
                      <div className="text-center mb-4">
                        <div className="fs-5 fw-semibold text-primary mb-2">
                          Welcome Back !
                        </div>
                        <div className="fs-7 text-muted">
                          Sign in to Continue to StoreKing.
                        </div>
                      </div>

                      {authStatus === "unauthenticated" ? (
                        <div className="text-center py-5">
                          <div className="d-inline-block">
                            <GoogleSignIn callback={onGSigninCallback} />
                          </div>
                        </div>
                      ) : null}

                      {authStatus === "loading" ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status"></div>
                          <div className="fs-7">
                            Please continue with Google.
                          </div>
                        </div>
                      ) : null}

                      {authStatus === "authenticated" ? (
                        <>
                          {user && user.email ? (
                            <div className="alert alert-info mb-3 d-flex align-items-center">
                              <div>
                                <i className="bi bi-envelop"></i>
                              </div>
                              <div className="fs-7 px-2 flex-grow-1">
                                <span> Your logged in email </span>
                                <span className="fw-bold">{user?.email}</span>
                              </div>
                              {/* <div>
                                <i
                                  onClick={doGSignOut}
                                  className="bi bi-power cursor-pointer"
                                ></i>
                              </div> */}
                            </div>
                          ) : null}

                          <form onSubmit={onSubmit} autoComplete="off">
                            <div className="form-group mb-4">
                              <label htmlFor="userName">Username</label>
                              <input
                                name="userName"
                                type="text"
                                maxLength={10}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    user: e.target.value.replace(/[^0-9]/g, ""),
                                  })
                                }
                                value={formData.user}
                                className="form-control"
                                placeholder="Enter Mobile No."
                              />
                            </div>

                            <div className="form-group mb-4">
                              <div className="row g-0">
                                <div className="col">
                                  <label>Password</label>
                                </div>
                                <div className="col text-end">
                                  <button
                                    className="btn btn-link pe-0 text-muted text-decoration-none"
                                    onClick={forgotPassword}
                                    type="button"
                                  >
                                    Forgot Password?
                                  </button>
                                </div>
                              </div>
                              <div className="input-group">
                                <input
                                  name="password"
                                  type={showPwd ? "text" : "password"}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      pwd: e.target.value,
                                    })
                                  }
                                  value={formData.pwd}
                                  className="form-control"
                                  placeholder="Enter Password"
                                  aria-label="Password"
                                />
                                <span className="input-group-text">
                                  <button
                                    className="btn btn-link p-0"
                                    onClick={() => setShowPwd((v) => !v)}
                                    type="button"
                                  >
                                    <i
                                      className={
                                        showPwd ? "bi-eye-slash" : "bi-eye"
                                      }
                                    ></i>
                                  </button>
                                </span>
                              </div>
                            </div>

                            <div className="mb-3">
                              <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary w-100"
                              >
                                {loading ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : null}
                                <span> Sign in </span>
                              </button>
                            </div>
                          </form>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="text-center fs-7 text-muted">
                  &#169; 2023 StoreKing.
                </div>
              </div>
            </div>

            {/* OTP MODAL */}
            <OtpModal
              sentTo={formData.user}
              show={showOtp && !busyLoader.loading}
              callback={otpModalCb}
            />

            <BusyLoader
              message={busyLoader.message}
              show={busyLoader.loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Login);
