import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, EmployeeService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  FileUpload,
  HighlightText,
  ImgRender,
  KeyVal,
  NoDataFound,
  PageInfo,
  Toaster,
  PageLoader,
  Spinner,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import produce from "immer";
// import ChangePassword from "./ChangePassword";
import { NavService } from "@sk/services";

const empDetails = AuthService.getLoggedInEmp();

const getData = async (id) => {
  const r = await EmployeeService.getEmployee(id);
  return r.resp || {};
};

const updatedEmployeeImage = async (id, params) => {
  const r = await EmployeeService.updateEmployee(id, params);
  return r.statusCode;
};

// To Attach Additional Config
const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const breadcrumb = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Employee Details",
  },
];

const Profile = () => {
  // Will Store all User Data
  const [data, setData] = useState({});

  // To display different State
  const [display, setDisplay] = useState("loading");

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const navigate = useNavigate();

  useEffect(() => {
    if (empDetails.employee) {
      loadData();
    } else {
      setDisplay("noDataFound");
    }
  }, [loadData]);

  // To load Employee Data
  const loadData = useCallback(async () => {
    setDisplay("loading");

    const d = await getData(empDetails.employee);

    if (d._id) {
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
    setData(d);

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    }
  }, [attachAllData, setAdditionalData]);

  // Reload Page
  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const imagesCb = async (e) => {
    const params = {
      ...data,
      image: e.asset,
    };
    const status = await updatedEmployeeImage(empDetails.employee, params);
    if (status == 200) {
      setData(
        produce((draft) => {
          draft.image = e.asset;
        })
      );
    } else {
      Toaster.error("Please upload again");
    }
  };

  const ChangePassword = useCallback(() => {
    NavService.to(navigate, "/auth/change-password");
  }, [navigate]);

  return (
    <>
      {/* When It is Loading  */}
      {display == "loading" && <PageLoader />}

      {/* When No Data Found */}
      {display == "noDataFound" && (
        <NoDataFound>
          <div>
            No Employee Data Found,
            <button className="btn btn-link" onClick={reloadPage}>
              Refresh <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </NoDataFound>
      )}
      {display === "details" && (
        <>
          {/* Page Info */}
          <PageInfo
            title="User Details"
            navigate={navigate}
            breadcrumbs={breadcrumb}
          />

          {/* Employee Details */}
          <AppCard>
            <div className="row   border-bottom pb-2 ">
              <div className="col-9">
                <div className="row">
                  {/* Details Sections  COL - 1  */}
                  <div className="col-6   border-end">
                    {/* Employee Name */}
                    <KeyVal
                      label="Employee Name :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data.firstName + " " + data.lastName}
                      </span>
                    </KeyVal>

                    {/* Employee Id  */}
                    <KeyVal
                      label="Employee ID :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className="fw-semibold">{data._id}</span>
                    </KeyVal>

                    {/* Mobile Number */}
                    <KeyVal
                      label="Mobile Number :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className="fw-semibold">
                        {data?.contact_details?.mobile_no}
                      </span>
                    </KeyVal>

                    {/* Email  */}
                    <KeyVal
                      label="Email :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data?.contact_details?.office_email_id}
                      </span>
                    </KeyVal>

                    {/* DOB  */}
                    <KeyVal
                      label="DOB :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        <DateFormatter date={data.dob} />
                      </span>
                    </KeyVal>

                    {/* Gender  */}
                    <KeyVal
                      label="Gender :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">{data.gender}</span>
                    </KeyVal>

                    {/* Last Logged In */}
                    <KeyVal
                      label="Last Logged In :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className="fw-semibold">
                        <DateFormatter date={data?.lastLogin?.at} />
                      </span>
                    </KeyVal>
                  </div>
                  {/* Details section COL 2 */}
                  <div className="col-6   border-end ps-2">
                    {/* Account Details */}
                    <KeyVal
                      label="Account Status :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        <HighlightText status={data.status} isBadge={true} />
                      </span>
                    </KeyVal>

                    {/* Employee Code */}
                    <KeyVal
                      label="Employee Code :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">{data.empCode}</span>
                    </KeyVal>

                    {/* Department  */}
                    <KeyVal
                      label="Department :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data.assignment_details.department}
                      </span>
                    </KeyVal>

                    {/* JOb Title  */}
                    <KeyVal
                      label="Job Title :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data.assignment_details.job_title}
                      </span>
                    </KeyVal>

                    {/* Designation  */}
                    <KeyVal
                      label="Job Title :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data.assignment_details.designation}
                      </span>
                    </KeyVal>

                    {/* Role ID  */}
                    <KeyVal
                      label="Role ID :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">{data.roleId}</span>
                    </KeyVal>

                    {/* Role ID  */}
                    <KeyVal
                      label="Address :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data?.address?.line1 + ", " + data.address?.line2}
                      </span>
                    </KeyVal>
                  </div>
                </div>
              </div>

              {/* Image Section */}
              <div className="col-3  algin-self-end">
                <div className="row justify-content-end">
                  {/* Image rendering */}
                  <div className="col-auto">
                    {!data.image ? (
                      <div className="upload-placeholder-t1">
                        <i className="bi bi-image"></i>
                      </div>
                    ) : (
                      <div className="uploaded-t1-img-cnt w-110">
                        <ImgRender
                          assetId={data?.image}
                          width="90"
                          height="90"
                        />
                      </div>
                    )}
                  </div>
                  {/* Action button  */}
                  <div className="col col-ms-auto align-self-end">
                    <label className="col-form-label fs-val-md">
                      Image <span className="text-danger"> * </span>
                    </label>
                    <div>
                      <FileUpload
                        template={2}
                        callback={imagesCb}
                        maxSize={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-auto me-4">
                <KeyVal
                  label="Created By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._createdBy?.name || "NA"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto pe-5 border-end">
                <KeyVal
                  label="Created At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold "
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.createdAt} />
                </KeyVal>
              </div>

              <div className="col-auto ps-4 pe-3">
                <KeyVal
                  label="Modified By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._lastUpdatedBy?.name || "NA"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto me-auto">
                <KeyVal
                  label="Modified At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.lastUpdated} />
                </KeyVal>
              </div>

              <div className="col-auto">
                <button
                  className="btn btn-primary fs-val-sm"
                  onClick={ChangePassword}
                >
                  Change Password
                </button>
              </div>
            </div>
          </AppCard>
        </>
      )}
    </>
  );
};

export default memo(Profile);
