import { AuthService, NavService } from "@sk/services";
import { NoDataFound, PageLoader, SideMenuService } from "@sk/uis";
import { each } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Init = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      if (!AuthService.isUserLoggedIn()) {
        NavService.to(navigate, "/auth/login");
        return;
      }

      let rd = { path: "" };

      // pick the first menu for the default redirection
      try {
        const groups = AuthService.getLoggedUserGroups();

        const menus = SideMenuService.getAllowedMenus(groups);

        each(menus, (menu) => {
          if (menu.subMenus?.length > 0) {
            each(menu.subMenus, (subMenu) => {
              if (subMenu.redirect) {
                rd.path = subMenu.redirect.path;
              }
              return rd.path ? false : true;
            });
          } else {
            rd.path = menu?.redirect?.path;
          }
          return rd.path ? false : true;
        });

        if (!rd.path) {
          setError(true);
          return;
        }

        NavService.to(navigate, rd.path);
      } catch (error) {
        // if there is any error - reload the page
        NavService.replace(navigate, "/auth/init", { t: new Date().getTime() });
      }
    })();
  }, [navigate]);

  return (
    <>
      {!error ? (
        <PageLoader />
      ) : (
        <div className="d-flex justify-content-center  align-items-center  vh-100">
          <NoDataFound>
            Something went wrong , Please reload again...
          </NoDataFound>
        </div>
      )}
    </>
  );
};

export default Init;
