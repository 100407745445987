import { memo, useState } from "react";
import styles from "../../styles/Auth.module.scss";
import { AuthService } from "@sk/services";
import { BusyLoader, OtpModal, Toaster } from "@sk/uis";
import produce from "immer";
import { useNavigate } from "react-router-dom";

import logo from "../../images/logo-header.svg";

const ForgotPassword = () => {
  const defaultFormData = { user: "", pwd: "", otpResp: {} };

  const navigate = useNavigate();

  const [busyLoader, setBusyLoader] = useState({ message: "", loading: false });
  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  const onSubmit = (ev) => {
    ev.preventDefault();
    const v = validate();
    if (v.status === false) {
      Toaster.error(v.msg);
      return;
    }
    doForgotPassword();
  };

  const doForgotPassword = async () => {
    const f = formData;
    setLoading(true);
    const r = await AuthService.doForgotPwd({
      userType: "Employee",
      username: f.user,
    });
    if (r.statusCode === 200) {
      const resp = r.resp;
      if (resp.otpRequired || resp.enableOtp) {
        setFormData(
          produce((draft) => {
            draft.otpResp = resp;
          })
        );
        setShowOtp(true);
      } else {
        setLoading(false);
        navigate("/auth/login");
        Toaster.success(resp.message || "Reset successfully");
      }
    } else {
      Toaster.error(r.resp.message || "Failed to login, please try again");
    }
    setLoading(false);
  };

  const validate = () => {
    const f = formData;
    let msg = "";
    if (!f.user) {
      msg = "Please provide username";
    } else {
      msg = "";
    }
    return {
      msg: msg,
      status: msg ? false : true,
    };
  };

  const otpVerifyCallback = async (data) => {
    const f = formData;
    setBusyLoader({
      loading: true,
      message: "We are validating the OTP, please wait",
    });
    const r = await AuthService.verifyLoginOtp({
      userid: f.otpResp.userId,
      id: f.otpResp._id,
      otp: Number(data.otp),
    });
    if (r.statusCode === 200) {
      setShowOtp(false);
      Toaster.success(
        "Password has been reset successfully and sent to your registered Email ID"
      );
      navigate("/auth/login");
    } else {
      Toaster.error(
        r.resp.message || "Failed to validate the OTP, please try again"
      );
    }
    setBusyLoader({ loading: false, message: "" });
  };

  const otpModalCb = (e) => {
    if (e.action == "close") {
      setShowOtp(false);
    }
    if (e.action == "verify") {
      otpVerifyCallback(e.data);
    }
  };

  const login = () => {
    navigate("/auth/login");
  };

  return (
    <>
      <div className={styles.authPage}>
        <div className={styles.mn}>
          <div className={styles.curve}>
            <div className={styles.cnt}>
              <div className={styles.logo}>
                <img alt="Logo" width={220} height={80} src={logo} />
              </div>
              <div className={styles.caption}>
                Brings the power of e-commerce to small towns in India
              </div>
            </div>
          </div>
          <div className={styles.inr}>
            <div className="row g-0 justify-content-center">
              <div className="col-md-8 col-lg-5 col-xl-4">
                <div className="card border-o mb-4 ms-3 me-3">
                  <div className="card-body">
                    <div className="p-3">
                      <div className="text-center mb-4">
                        <div className="fs-5 fw-semibold text-primary mb-2">
                          Forgot Password?
                        </div>
                        <div className="fs-7 text-muted">
                          Reset password with StoreKing.
                        </div>
                      </div>

                      {/* NOTE */}
                      <div className="alert alert-warning fs-7">
                        Password will be sent to your registered Mobile Number.
                      </div>

                      <form onSubmit={onSubmit} autoComplete="off">
                        <div className="form-group mb-4">
                          <label className="form-label">
                            Username <span className="text-danger">*</span>
                          </label>
                          <input
                            name="userName"
                            type="text"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                user: e.target.value.replace(/[^0-9]/g, ""),
                              })
                            }
                            value={formData.user}
                            className="form-control"
                            placeholder="Enter Mobile No."
                          />
                        </div>

                        <div className="mb-3">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : null}
                            <span> Submit </span>
                          </button>
                        </div>

                        <div className="text-center fs-7 text-muted">
                          <span>Back to Login, </span>
                          <span
                            className="fw-semibold cursor-pointer text-primary"
                            onClick={login}
                          >
                            click here
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center fs-7 text-muted">
              &#169; 2023 StoreKing.
            </div>
            {/* OTP MODAL */}
            <OtpModal
              sentTo={formData.user}
              show={showOtp}
              callback={otpModalCb}
            />

            <BusyLoader
              message={busyLoader.message}
              loading={busyLoader.loading}
            ></BusyLoader>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ForgotPassword);
