import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import ChangePassword from "./pages/ChangePassword";
import { ForgotPassword } from "./pages/ForgotPassword";
import Init from "./pages/Init";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Layout from "./Layout";

const routes = createHashRouter([
  {
    path: "/",
    element: <Init />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/auth/init",
    element: <Init />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/auth/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/auth/user",
    element: <Layout />,
    children: [
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={routes} />;
};

export default memo(Router);
